import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SupportPage = () => (
  <Layout>
    <SEO title="Hasznos szolgáltatások, cégek keresése" />
    <h1>Hasznos szolgáltatások</h1>
    
    <div className="content-wrapper">
        <div className="header-wrapper">
            <div className="cardify-content-wrapper">
                <a href="https://www.munch.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title">Munch</h5>
                    <p className="cardify-content-alt">Ételmentő Kezdeményezés</p>

                    <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Országos</p>
                </a>

                <a href="https://www.share-now.com/hu/hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title">ShareNow</h5>
                    <p className="cardify-content-alt">CAR SHARING</p>

                    <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Budapest</p>
                </a>

                <a href="https://ferjkolcsonzes.hu/ferjkolcsonzes/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title">FérjKölcsönzés</h5>
                    <p className="cardify-content-alt">Lakás körüli segítségnyújtás</p>

                    <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Országos</p>
                </a>

                <a href="https://www.hubhub.com/hu/budapest-kiraly-26/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title">HubDub Budapest</h5>
                    <p className="cardify-content-alt">Iroda/asztal bérlés</p>

                    <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Budapest</p>
                </a>

                <a href="https://kifli.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title">Kifli</h5>
                    <p className="cardify-content-alt">Ételszállítás</p>

                    <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Budapest</p>
                </a>

                <a href="https://greengo.com/hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title">GreenGO</h5>
                    <p className="cardify-content-alt">E-CAR SHARING</p>

                    <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Budapest</p>
                </a>
            </div>
        </div>
    </div>
  </Layout>
)

export default SupportPage
